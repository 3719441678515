import { HeadingStyle, MantineFontSizesValues, rem } from '@mantine/core';

interface MantineHeadings {
  fontFamily: string;
  fontWeight: string;
  sizes: {
    h1: HeadingStyle;
    h2: HeadingStyle;
    h3: HeadingStyle;
    h4: HeadingStyle;
    h5: HeadingStyle;
    h6: HeadingStyle;
  };
}

const fontName = 'Poppins';

const fontFamily = `${fontName}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;

const fontSizes: MantineFontSizesValues = {
  xs: rem(12),
  sm: rem(14),
  md: rem(16),
  lg: rem(18),
  xl: rem(20),
};

const headings: MantineHeadings = {
  fontFamily,
  fontWeight: '400',
  sizes: {
    h1: {
      fontSize: rem(34),
      lineHeight: '44px',
    },
    h2: {
      fontSize: rem(26),
      lineHeight: '35px',
    },
    h3: {
      fontSize: rem(22),
      lineHeight: '30px',
    },
    h4: {
      fontSize: rem(18),
      lineHeight: '26px',
    },
    h5: {
      fontSize: rem(16),
      lineHeight: '24px',
    },
    h6: {
      fontSize: rem(14),
      lineHeight: '21px',
    },
  },
};

export const MantineFontConfig = {
  fontFamily,
  fontName,
  fontSizes,
  headings,
};
