import { InputWrapper } from '@mantine/core';
import classes from './InputWrapper.module.scss';

export const InputWrapperConfig = InputWrapper.extend({
  classNames: {
    label: classes.label,
    error: classes.error,
    root: classes.root,
  },
});
