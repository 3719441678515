import { Tooltip } from '@mantine/core';
import classes from './Tooltip.module.scss';

export const TooltipConfig = Tooltip.extend({
  classNames: {
    tooltip: classes.tooltip,
  },
  defaultProps: {
    arrowOffset: 10,
    arrowRadius: 3,
    arrowSize: 8,
    color: 'dark',
    multiline: true,
    withArrow: true,
    maw: 300,
  },
});
