import { Popover } from '@mantine/core';
import classes from './Popover.module.scss';

export const PopoverConfig = Popover.extend({
  defaultProps: {
    arrowRadius: 4,
    arrowSize: 10,
    position: 'bottom',
    withArrow: true,
    transitionProps: {
      transition: 'pop',
    },
  },
  classNames: {
    dropdown: classes.dropdown,
  },
});
