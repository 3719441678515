import { DEFAULT_THEME, MantineColorsTuple } from '@mantine/core';

export const PISANO_COLORS: Record<string, MantineColorsTuple> = {
  ...DEFAULT_THEME.colors,
  dark: ['#c1c1d4', '#adadc5', '#9191b2', '#6d6d99', '#494969', '#1c1c28', '#181822', '#14141d', '#111118', '#0f0f15'],
  light: ['#f9f9fa', '#f6f6f9', '#f4f4f7', '#f0f0f4', '#ebebf0', '#e4e4eb', '#bcbccd', '#9a9ab4', '#7d7d9f', '#67678b'],
  gray: ['#dbdde8', '#cfd2e1', '#bfc3d7', '#aaafc9', '#8d94b7', '#67709f', '#565f89', '#495074', '#3e4463', '#353a54'],
  red: ['#ffd1d1', '#ffc1c1', '#ffacac', '#ff9191', '#ff6c6c', '#ff3b3b', '#ff0c0c', '#e30000', '#c10000', '#a40000'],
  green: ['#b8fdec', '#a0fce5', '#81fbdd', '#57fad1', '#1ff8c2', '#06c494', '#05a77e', '#048e6b', '#04785b', '#03664e'],
  blue: ['#c6dfff', '#b2d5ff', '#99c7ff', '#77b4ff', '#499bff', '#0d7bff', '#0066e4', '#0057c1', '#004aa4', '#003f8c'],
  yellow: [
    '#fff1ce',
    '#ffecbd',
    '#ffe6a7',
    '#ffde8a',
    '#ffd363',
    '#ffc32f',
    '#ffb702',
    '#da9c00',
    '#b98500',
    '#9e7100',
  ],
  orange: [
    '#ffe0bc',
    '#ffd6a6',
    '#ffc888',
    '#ffb561',
    '#ff9d2c',
    '#e57a00',
    '#c36800',
    '#a55800',
    '#8d4b00',
    '#784000',
  ],
  teal: ['#b4faff', '#9cf8ff', '#7af6ff', '#4ef3ff', '#13efff', '#00b7c4', '#009ba6', '#00848d', '#007078', '#005f66'],
  purple: [
    '#d5aaff',
    '#c78eff',
    '#b468ff',
    '#9b36ff',
    '#7900f2',
    '#4c0099',
    '#410082',
    '#37006f',
    '#2f005e',
    '#280050',
  ],
  kindness: [
    '#ffc4e5',
    '#ffb0dd',
    '#ff96d2',
    '#ff73c2',
    '#ff44ae',
    '#ff0695',
    '#de007e',
    '#bd006b',
    '#a0005b',
    '#88004d',
  ],
};
