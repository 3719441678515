import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export namespace TimeConstant {
  export const ACCOUNT_CHANGE_NOTIFICATION_AUTO_CLOSE_DURATION = dayjs.duration(1.5, 'second').asMilliseconds();
  export const BROWSER_IDLE_DURATION = dayjs.duration(2, 'minute').asMilliseconds();
  export const NOTIFICATION_AUTO_CLOSE_DURATION = dayjs.duration(4, 'second').asMilliseconds();
  export const OTP_EXPIRE_DURATION = dayjs.duration(2, 'minute').asSeconds();
  export const USER_NOTIFICATION_INTERVAL_DURATION = dayjs.duration(1, 'minute').asMilliseconds();
}
