/**
 * Input: snakeCaseExample
 * Output: SnakeCaseExample
 * Output (withSpace): Snake Case Example
 */
export const camelCaseToTitleCase = (value: string, withSpace = false) =>
  value.replace(/([A-Z])/g, withSpace ? ' $1' : '$1').replace(/^./, (match) => match.toUpperCase());

/**
 * Input: snake_case_example
 * Output: SnakeCaseExample
 * Output (withSpace): Snake Case Example
 */
export function snakeCaseToTitleCase(str: string, withSpace = false): string {
  return str
    .toLowerCase()
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(withSpace ? ' ' : '');
}

/**
 * Input: example
 * Output: Example
 */
export const capitalize = (value: string, lowerRest = false) =>
  value.charAt(0).toUpperCase() + (lowerRest ? value.slice(1).toLowerCase() : value.slice(1));

/**
 * Input: buildMessage('example.{{interpolation}}', { interpolation: 'replaceWithMe' })
 * Output: example.replaceWithMe
 */
export function interpolate(message = '', options: Record<string, any> = {}): string {
  return Object.keys(options).reduce(
    (acc, key) => acc.replace(new RegExp(`{{\\s*${key}\\s*}}`, 'g'), options[key] || key),
    message,
  );
}

/**
 * Input: F1C85E
 * Output: #F1C85E
 */
export function ensureHexColor(value: string): string {
  if (!value || value.startsWith('#')) {
    return value;
  }
  return `#${value}`;
}

export function buildPisanoTitle(value: string): string {
  if (!value) {
    return 'Pisano';
  }
  return `${value} | Pisano`;
}

export function getNameInitials(name: string, count = 2): string {
  if (!name) {
    return 'X';
  }
  return name
    .split(' ')
    .slice(0, count) // Take first two items
    .map((item: string) => item.charAt(0))
    .join('');
}

export function escapeHtmlEntities(value: string) {
  if (!value) {
    return '';
  }

  return value.replace(/&(amp|apos|lt|gt|quot);/g, (_, match) => {
    switch (match) {
      case 'amp':
        return '&';
      case 'apos':
        return "'";
      case 'lt':
        return '<';
      case 'gt':
        return '>';
      case 'quot':
        return '\\';
    }
  });
}

export function buildEndpointWithQuery(value: string, query: string) {
  if (!query) {
    return value;
  }

  return `${value}?${query}`;
}

export function buildMailToLink(values: Record<string, string>) {
  return (
    'mailto:?' +
    Object.entries(values)
      .map(([key, value]) => `${key}=${value}`, '')
      .join('&')
  );
}

export function isValidHex(color: string) {
  if (!color || typeof color !== 'string') {
    return false;
  }

  // Validate hex values
  if (color.substring(0, 1) === '#') {
    color = color.substring(1);
  }

  switch (color.length) {
    case 3:
      return /^[0-9A-F]{3}$/i.test(color);
    case 6:
      return /^[0-9A-F]{6}$/i.test(color);
    case 8:
      return /^[0-9A-F]{8}$/i.test(color);
    default:
      return false;
  }
}

export function truncateString(str: string, maxLength: number = 20): string {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength);
  }
}
