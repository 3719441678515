import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Indicator } from '@mantine/core';
import { DatePickerInput, DatePickerInputProps } from '@mantine/dates';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { PISANO_COLORS } from '../../colors';
import classes from './DatePickerInput.module.scss';

dayjs.extend(isToday);

export const DatePickerInputConfigDefaultProps: Partial<DatePickerInputProps> = {
  clearable: true,
  valueFormat: 'DD/MM/YYYY',
  leftSection: <FontAwesomeIcon icon={faCalendar} color={PISANO_COLORS.gray[2]} />,
  renderDay(date: Date) {
    const today = dayjs(date).isToday();
    const day = date.getDate();
    return (
      <Indicator size={6} color="red" offset={-5} disabled={!today}>
        {day}
      </Indicator>
    );
  },
};

export const DatePickerInputConfig = DatePickerInput.extend({
  classNames: {
    input: classes.input,
  },
  defaultProps: DatePickerInputConfigDefaultProps,
});
