import { ENV } from '@vision/ui/utils';

const GTM_SCRIPT = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${ENV.GTM_KEY}');
`;

const GTM_NOSCRIPT = `
<iframe src="https://www.googletagmanager.com/ns.html?id=${ENV.GTM_KEY}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

function setGTMScript() {
  const el = document.createElement('script');
  el.textContent = GTM_SCRIPT;
  document.head.appendChild(el);
}

function setGTMNoScript() {
  const el = document.createElement('noscript');
  el.textContent = GTM_NOSCRIPT;
  document.body.prepend(el);
}

function initiliazeGTM() {
  if (!ENV.GTM_ENABLED) {
    return;
  }

  setGTMScript();
  setGTMNoScript();
}

initiliazeGTM();
