import { faEyeDropper } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorInput } from '@mantine/core';
import { PISANO_COLORS } from '../../colors';

export const ColorInputConfig = ColorInput.extend({
  defaultProps: {
    eyeDropperIcon: <FontAwesomeIcon icon={faEyeDropper} fontSize={12} color={PISANO_COLORS.gray[2]} />,
  },
});
