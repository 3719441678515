import { StringSchema, addMethod, string } from 'yup';
import { isValidHex } from './string.utils';

/*
 * After adding a new validation method, do not forget to define the type in the "declare module 'yup'" section in the file below.
 *
 * src/vision-ui/src/env.d.ts
 */

addMethod<StringSchema>(string, 'hex', function (message?: string) {
  return this.test('hex', message, function (input: string) {
    return isValidHex(input)
      ? true
      : this.createError({
          path: this.path,
          message,
        });
  });
});
