import { SupportedLanguages } from '@vision/ui/interfaces';
import { __DEV__ } from '@vision/ui/utils';
import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const DEFAULT_LANG = 'en';
export const DEFAULT_NS = 'translation';
export const SUPPORTED_LANGUAGES: SupportedLanguages[] = ['en', 'tr'];

export async function initializeI18n() {
  return (
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      // For all options read: https://www.i18next.com/overview/configuration-options
      .init({
        initImmediate: false,
        lowerCaseLng: true,
        supportedLngs: SUPPORTED_LANGUAGES,
        detection: {
          lookupLocalStorage: 'vision-lang',
        },
        // Default translation files/namepsaces to load on initialize
        ns: ['http', 'translation', 'validation', 'support-drawer', 'routes'],
        fallbackLng: false,
        defaultNS: DEFAULT_NS,
        missingKeyHandler: (lngs: readonly string[], ns: string, key: string) => {
          if (__DEV__) {
            const fileName = `"${i18n.language}/${ns}.json"`;
            console.error(
              `%c "${key}" is missing for ${fileName} namespace.`,
              'background: #ffd1d1; color: #ff3b3b; font-size: 30px',
            );
          }
        },
        backend: {
          loadPath: `/locales/{{lng}}/{{ns}}.json?v=${window.__PSN_BUILD_INFO__.TIMESTAMP}`,
        },
      } as InitOptions)
  );
}

export default i18n;
