import { Accordion } from '@mantine/core';
import classes from './Accordion.module.scss';

export const AccordionConfig = Accordion.extend({
  classNames: {
    chevron: classes.chevron,
    content: classes.content,
    control: classes.control,
    item: classes.item,
    label: classes.label,
  },
});
