import { ScrollArea } from '@mantine/core';
import classes from './ScrollArea.module.scss';

export const ScrollAreaConfig = ScrollArea.extend({
  classNames: {
    root: classes.root,
    scrollbar: classes.scrollbar,
    thumb: classes.thumb,
  },
});
