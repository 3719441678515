import { Modal } from '@mantine/core';
import classes from './Modal.module.scss';

export const MODAL_CLOSE_DURATION = 200;

export const ModalConfig = Modal.extend({
  classNames: {
    close: classes.close,
    header: classes.header,
    title: classes.title,
  },
  defaultProps: {
    padding: 0,
    size: 600,
    transitionProps: {
      duration: MODAL_CLOSE_DURATION,
      transition: 'pop',
    },
  },
});
