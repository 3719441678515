import { Switch } from '@mantine/core';
import classes from './Switch.module.scss';

export const SwitchConfig = Switch.extend({
  classNames: {
    input: classes.input,
    label: classes.label,
    root: classes.root,
    thumb: classes.thumb,
    track: classes.track,
  },
});
