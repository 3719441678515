export enum Operator {
  BETWEEN = 'between',
  CONTAINS = 'contains',
  EQUALS = 'equals',
  EXACT = 'exact',
  GREATER_THAN = 'greater_than',
  GREATER_THAN_OR_EQUALS = 'greater_than_or_equals',
  IS = 'is',
  LESS_THAN = 'less_than',
  LESS_THAN_OR_EQUALS = 'less_than_or_equals',
  NOT_CONTAINS = 'not_contains',
  NOT_EQUALS = 'not_equals',
}
