import { MIME_TYPES } from '@mantine/dropzone';
import { KeyValue } from '@vision/ui/interfaces';

export type MimeTypeKey = keyof typeof MIME_TYPES;

export const MIME_TYPES_KEY_VALUE: KeyValue<string, MimeTypeKey>[] = Object.entries(MIME_TYPES).map(([key, value]) => ({
  key: key as keyof typeof MIME_TYPES,
  value,
}));

export function findMimeTypeFromValue(value: string): KeyValue<string, MimeTypeKey> {
  return MIME_TYPES_KEY_VALUE.find((item) => item.value === value);
}
