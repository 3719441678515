import { string } from 'yup';

const MIN_PHONE_LENGTH = 7;
const MAX_PHONE_LENGTH = 15;

export const PhoneValidationRules = {
  validations: {
    'validation:rules.generic.min': {
      count: MIN_PHONE_LENGTH,
    },
    'validation:rules.generic.max': {
      count: MAX_PHONE_LENGTH,
    },
  },
  schema: string()
    .min(MIN_PHONE_LENGTH, 'validation:rules.generic.min')
    .max(MAX_PHONE_LENGTH, 'validation:rules.generic.max'),
};
