import { Operator, SchemaFieldType as SFT } from '@vision/ui/enums';
import { WorkflowTaskCollectionRuleContainer } from '../interfaces';

// Some parts of project are using symbols, some are using keys as operator values. So consider any changes in this file can be breaking changes.

interface WorkflowOperator {
  key: Operator;
  symbol?: string;
  validSchemaFieldTypes?: SFT[];
}

const EQUALS: WorkflowOperator = {
  key: Operator.EQUALS,
  symbol: '=',
  validSchemaFieldTypes: [SFT.INTEGER, SFT.FLOAT],
};

const NOT_EQUALS: WorkflowOperator = {
  key: Operator.NOT_EQUALS,
  symbol: '!=',
  validSchemaFieldTypes: [SFT.INTEGER, SFT.FLOAT],
};

const GREATER_THAN: WorkflowOperator = {
  key: Operator.GREATER_THAN,
  symbol: '>',
  validSchemaFieldTypes: [SFT.DATE, SFT.INTEGER, SFT.FLOAT],
};

const GREATER_THAN_OR_EQUALS: WorkflowOperator = {
  key: Operator.GREATER_THAN_OR_EQUALS,
  symbol: '>=',
  validSchemaFieldTypes: [SFT.INTEGER, SFT.FLOAT],
};
const LESS_THAN: WorkflowOperator = {
  key: Operator.LESS_THAN,
  symbol: '<',
  validSchemaFieldTypes: [SFT.DATE, SFT.INTEGER, SFT.FLOAT],
};

const LESS_THAN_OR_EQUALS: WorkflowOperator = {
  key: Operator.LESS_THAN_OR_EQUALS,
  symbol: '<=',
  validSchemaFieldTypes: [SFT.INTEGER, SFT.FLOAT],
};

const EXACT: WorkflowOperator = {
  key: Operator.EXACT,
  symbol: '=',
  validSchemaFieldTypes: [SFT.STRING, SFT.DATE],
};

const BETWEEN: WorkflowOperator = {
  key: Operator.BETWEEN,
  symbol: 'between',
  validSchemaFieldTypes: [SFT.DATE, SFT.INTEGER, SFT.FLOAT],
};

const CONTAINS: WorkflowOperator = {
  key: Operator.CONTAINS,
  symbol: 'contains',
  validSchemaFieldTypes: [SFT.STRING, SFT.LIST],
};

const NOT_CONTAINS: WorkflowOperator = {
  key: Operator.NOT_CONTAINS,
  symbol: 'not-contains',
  validSchemaFieldTypes: [SFT.STRING, SFT.LIST],
};

const IS: WorkflowOperator = {
  key: Operator.IS,
  symbol: 'is',
  validSchemaFieldTypes: [SFT.DATE],
};

export function getSchemaFieldOperators(schemaFieldType?: SFT): WorkflowOperator[] {
  switch (schemaFieldType) {
    case 'string':
      return [EXACT, CONTAINS, NOT_CONTAINS];
    case 'boolean':
      return [EQUALS];
    case 'date':
      return [EXACT, IS, BETWEEN, GREATER_THAN, LESS_THAN];
    case 'integer':
    case 'float':
      return [EQUALS, NOT_EQUALS, GREATER_THAN, GREATER_THAN_OR_EQUALS, LESS_THAN, LESS_THAN_OR_EQUALS, BETWEEN];
    case 'list':
      return [CONTAINS, NOT_CONTAINS];
    default:
      return [
        BETWEEN,
        CONTAINS,
        EQUALS,
        EXACT,
        GREATER_THAN,
        GREATER_THAN_OR_EQUALS,
        IS,
        LESS_THAN,
        LESS_THAN_OR_EQUALS,
        NOT_CONTAINS,
        NOT_EQUALS,
      ];
  }
}

export function getSurveyActivityOperators(): WorkflowOperator[] {
  return [EQUALS, NOT_EQUALS];
}

export function getFlowOperators(): WorkflowOperator[] {
  return [CONTAINS, NOT_CONTAINS];
}

export function getOperatorForRule(ruleContainer: WorkflowTaskCollectionRuleContainer): WorkflowOperator {
  return getSchemaFieldOperators().find(
    (operator) =>
      operator.symbol === ruleContainer.rule.operator &&
      operator.validSchemaFieldTypes.includes(ruleContainer.schema_field.field_type),
  );
}

export function getOperatorByKey(key: string): WorkflowOperator {
  return getSchemaFieldOperators().find((operator) => operator.key === key);
}
