import { Drawer } from '@mantine/core';
import { PISANO_COLORS } from '../../colors';
import classes from './Drawer.module.scss';

export const DrawerConfig = Drawer.extend({
  classNames: {
    close: classes.close,
    content: classes.content,
    header: classes.header,
    body: classes.body,
    title: classes.title,
  },
  defaultProps: {
    overlayProps: {
      color: PISANO_COLORS.dark[5],
      opacity: 0.5,
    },
    position: 'right',
    size: 600,
  },
});
