const CHARMAP: Record<string, string> = {
  á: 'a',
  Á: 'A',
  À: 'A',
  à: 'a',
  Ă: 'A',
  ă: 'a',
  Ắ: 'A',
  ắ: 'a',
  ằ: 'a',
  Ằ: 'A',
  Ẵ: 'A',
  ẵ: 'a',
  ẳ: 'a',
  Ẳ: 'A',
  â: 'a',
  Â: 'A',
  ấ: 'a',
  Ấ: 'A',
  ầ: 'a',
  Ầ: 'A',
  ǎ: 'a',
  Ǎ: 'A',
  å: 'a',
  Å: 'A',
  Ǻ: 'A',
  ǻ: 'a',
  ä: 'a',
  Ä: 'A',
  a̋: 'a',
  A̋: 'A',
  Ã: 'A',
  ã: 'a',
  A̧: 'A',
  a̧: 'a',
  Ą: 'A',
  ą: 'a',
  ā: 'a',
  Ā: 'A',
  ȁ: 'a',
  Ȁ: 'A',
  ȃ: 'a',
  Ȃ: 'A',
  Ặ: 'A',
  ặ: 'a',
  æ: 'a', // ae
  Æ: 'A', // AE
  Ǽ: 'A', // AE
  ǽ: 'a', // ae
  b̌: 'b',
  B̌: 'B',
  b̧: 'b',
  B̧: 'B',
  ć: 'c',
  Ć: 'C',
  c̆: 'c',
  C̆: 'C',
  ĉ: 'c',
  Ĉ: 'C',
  č: 'c',
  Č: 'C',
  ċ: 'c',
  Ċ: 'C',
  Ç: 'C',
  ç: 'c',
  Ḉ: 'C',
  ḉ: 'c',
  č̣: 'c',
  Č̣: 'C',
  Ď: 'D',
  ď: 'd',
  ḑ: 'd',
  Ḑ: 'D',
  đ: 'd',
  Đ: 'D',
  Ð: 'D',
  ð: 'd',
  É: 'E',
  é: 'e',
  È: 'E',
  è: 'e',
  Ĕ: 'E',
  ĕ: 'e',
  ê: 'e',
  Ê: 'E',
  Ế: 'E',
  ế: 'e',
  ề: 'e',
  Ề: 'E',
  Ê̌: 'E',
  ê̌: 'e',
  Ě: 'E',
  ě: 'e',
  ë: 'e',
  Ë: 'E',
  e̋: 'e',
  E̋: 'E',
  Ė: 'E',
  ė: 'e',
  Ȩ: 'E',
  ȩ: 'e',
  ḝ: 'e',
  Ḝ: 'E',
  Ę: 'E',
  ę: 'e',
  Ē: 'E',
  ē: 'e',
  Ḗ: 'E',
  ḗ: 'e',
  Ḕ: 'E',
  ḕ: 'e',
  Ȅ: 'E',
  ȅ: 'e',
  Ȇ: 'E',
  ȇ: 'e',
  ɛ̧: 'e',
  Ɛ̧: 'E',
  f̌: 'f',
  F̌: 'F',
  ƒ: 'f',
  ǵ: 'g',
  Ǵ: 'G',
  ğ: 'g',
  Ğ: 'G',
  Ĝ: 'G',
  ĝ: 'g',
  ǧ: 'g',
  Ǧ: 'G',
  Ġ: 'G',
  ġ: 'g',
  ģ: 'g',
  Ģ: 'G',
  Ĥ: 'H',
  ĥ: 'h',
  ȟ: 'h',
  Ȟ: 'H',
  ḩ: 'h',
  Ḩ: 'H',
  ħ: 'h',
  Ħ: 'H',
  ḫ: 'h',
  Ḫ: 'H',
  í: 'i',
  Í: 'I',
  Ì: 'I',
  ì: 'i',
  Ĭ: 'I',
  ĭ: 'i',
  Î: 'I',
  î: 'i',
  ǐ: 'i',
  Ǐ: 'I',
  ï: 'i',
  Ï: 'I',
  ḯ: 'i',
  Ḯ: 'I',
  I̋: 'I',
  i̋: 'i',
  ĩ: 'i',
  Ĩ: 'I',
  İ: 'I',
  I̧: 'I',
  i̧: 'i',
  Į: 'I',
  į: 'i',
  ī: 'i',
  Ī: 'I',
  ȉ: 'i',
  Ȉ: 'I',
  ȋ: 'i',
  Ȋ: 'I',
  ĳ: 'i', // ij
  Ĳ: 'I', // IJ
  ı: 'i',
  Ɨ̧: 'I',
  ɨ̧: 'i',
  ĵ: 'j',
  Ĵ: 'J',
  ǰ: 'j',
  J̌: 'J',
  Ḱ: 'K',
  ḱ: 'k',
  k̆: 'k',
  K̆: 'K',
  ǩ: 'k',
  Ǩ: 'K',
  ķ: 'k',
  Ķ: 'K',
  Ĺ: 'L',
  ĺ: 'l',
  Ľ: 'L',
  ľ: 'l',
  ļ: 'l',
  Ļ: 'L',
  ł: 'l',
  Ł: 'l',
  Ŀ: 'L',
  ŀ: 'l',
  Ḿ: 'M',
  ḿ: 'm',
  m̆: 'm',
  M̆: 'M',
  m̌: 'm',
  M̌: 'M',
  M̧: 'M',
  m̧: 'm',
  Ń: 'N',
  ń: 'n',
  Ǹ: 'N',
  ǹ: 'n',
  n̆: 'n',
  N̆: 'N',
  Ň: 'N',
  ň: 'n',
  ñ: 'n',
  Ñ: 'N',
  Ņ: 'N',
  ņ: 'n',
  ó: 'o',
  Ó: 'O',
  Ò: 'O',
  ò: 'o',
  Ŏ: 'O',
  ŏ: 'o',
  Ô: 'O',
  ô: 'o',
  ố: 'o',
  Ố: 'O',
  ồ: 'o',
  Ồ: 'O',
  Ǒ: 'O',
  ǒ: 'o',
  Ö: 'O',
  ö: 'o',
  ő: 'o',
  Ő: 'O',
  Õ: 'O',
  õ: 'o',
  ṍ: 'o',
  Ṍ: 'O',
  Ø: 'O',
  ø: 'o',
  Ǿ: 'O',
  ǿ: 'o',
  o̧: 'o',
  O̧: 'O',
  Ō: 'O',
  ō: 'o',
  ṓ: 'o',
  Ṓ: 'O',
  ṑ: 'o',
  Ṑ: 'O',
  ȍ: 'o',
  Ȍ: 'O',
  ȏ: 'o',
  Ȏ: 'O',
  ơ: 'o',
  Ơ: 'O',
  œ: 'o', // oe
  Œ: 'O', // OE
  ṕ: 'p',
  Ṕ: 'P',
  p̆: 'p',
  P̆: 'P',
  P̌: 'P',
  p̌: 'p',
  Q̌: 'Q',
  q̌: 'q',
  Q̧: 'Q',
  q̧: 'q',
  ŕ: 'r',
  Ŕ: 'R',
  r̆: 'r',
  R̆: 'R',
  Ř: 'R',
  ř: 'r',
  ŗ: 'r',
  Ŗ: 'R',
  Ř̩: 'R',
  ř̩: 'r',
  ȑ: 'r',
  Ȑ: 'R',
  Ȓ: 'R',
  ȓ: 'r',
  Ś: 'S',
  ś: 's',
  Ṥ: 'S',
  ṥ: 's',
  Ŝ: 'S',
  ŝ: 's',
  š: 's',
  Š: 'S',
  Ṧ: 'S',
  ṧ: 's',
  ş: 's',
  Ş: 'S',
  ș: 's',
  Ș: 'S',
  ſ: 's',
  T̆: 'T',
  t̆: 't',
  Ť: 'T',
  ť: 't',
  ţ: 't',
  Ţ: 'T',
  Ț: 'T',
  ț: 't',
  ŧ: 't',
  Ŧ: 'T',
  Ú: 'U',
  ú: 'u',
  ù: 'u',
  Ù: 'U',
  ŭ: 'u',
  Ŭ: 'U',
  Û: 'U',
  û: 'u',
  ǔ: 'u',
  Ǔ: 'U',
  Ů: 'U',
  ů: 'u',
  ü: 'u',
  Ü: 'U',
  ǘ: 'u',
  Ǘ: 'U',
  Ǜ: 'U',
  ǜ: 'u',
  ǚ: 'u',
  Ǚ: 'U',
  ǖ: 'u',
  Ǖ: 'U',
  Ű: 'U',
  ű: 'u',
  ũ: 'u',
  Ũ: 'U',
  Ṹ: 'U',
  ṹ: 'u',
  u̧: 'u',
  U̧: 'U',
  Ų: 'U',
  ų: 'u',
  Ū: 'U',
  ū: 'u',
  ȕ: 'u',
  Ȕ: 'U',
  Ȗ: 'U',
  ȗ: 'u',
  ư: 'u',
  Ư: 'U',
  ứ: 'u',
  Ứ: 'U',
  ừ: 'u',
  Ừ: 'U',
  V̆: 'V',
  v̆: 'v',
  v̌: 'v',
  V̌: 'V',
  Ẃ: 'W',
  ẃ: 'w',
  Ẁ: 'W',
  ẁ: 'w',
  ŵ: 'w',
  Ŵ: 'W',
  W̌: 'W',
  w̌: 'w',
  x́: 'x',
  X́: 'X',
  X̆: 'X',
  x̆: 'x',
  x̌: 'x',
  X̌: 'X',
  X̧: 'X',
  x̧: 'x',
  Ý: 'Y',
  ý: 'y',
  Ỳ: 'Y',
  ỳ: 'y',
  y̆: 'y',
  Y̆: 'Y',
  Ŷ: 'Y',
  ŷ: 'y',
  y̌: 'y',
  Y̌: 'Y',
  ÿ: 'y',
  Ÿ: 'Y',
  ź: 'z',
  Ź: 'Z',
  Ž: 'Z',
  ž: 'z',
  Ż: 'Z',
  ż: 'z',
  Z̧: 'Z',
  z̧: 'z',
};

const DIACRITICS = new RegExp(Object.keys(CHARMAP).join('|'), 'g');

export function normalizeDiacritics(text: string): string {
  if (!text) {
    return;
  }
  return text.replace(DIACRITICS, (match) => CHARMAP[match]);
}
