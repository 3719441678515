// Root contains the main dependencies and providers of the base app
//  - React, ReactDom, Mantine, i18n, Fonts etc.)
// App contains the main structure of the base app

// These are the two main chunks that are used to render the core structure of the app
// Importing them with Promise.all (by using HTTP/2 multiplexing) we can load them in parallel
// and achieve the best possible performance
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/dropzone/styles.css';
import './styles/index.scss';

// Do not change order
import './config/env.config';
import './config/gtm.config';
import './config/sentry.config';
import './config/dayjs.config';

Promise.all([import('@vision/ui/Root'), import('@vision/ui/App')]).then(([{ default: render }, { default: App }]) => {
  render(App);
});

// ts(1208), do not remove
export {};
