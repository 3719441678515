import { Input, MantineSize, rem } from '@mantine/core';
import classes from './Input.module.scss';

export const InputSizes: Record<MantineSize, string> = {
  xs: rem(30),
  sm: rem(36),
  md: rem(42),
  lg: rem(50),
  xl: rem(60),
};

export const InputConfig = Input.extend({
  classNames: {
    input: classes.input,
    section: classes.section,
  },
});
