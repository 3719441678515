import {
  faBed,
  faBuilding,
  faCar,
  faCartShopping,
  faChartNetwork,
  faComment,
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
  faHourglassEnd,
  faQuoteLeft,
  faTag,
  faThumbsDown,
  faThumbsUp,
  faTriangleExclamation,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';

export enum ReportWizardIconShortCodes {
  ICON_BED = 'ICON_BED',
  ICON_BUILDING = 'ICON_BUILDING',
  ICON_CAR = 'ICON_CAR',
  ICON_CART = 'ICON_CART',
  ICON_COMMENT = 'ICON_COMMENT',
  ICON_DASHBOARD = 'ICON_DASHBOARD',
  ICON_FACE_FROWN = 'ICON_FACE_FROWN',
  ICON_FACE_MEH = 'ICON_FACE_MEH',
  ICON_FACE_SMILE = 'ICON_FACE_SMILE',
  ICON_QUOTE = 'ICON_QUOTE',
  ICON_TAG = 'ICON_TAG',
  ICON_THUMBS_DOWN = 'ICON_THUMBS_DOWN',
  ICON_THUMBS_UP = 'ICON_THUMBS_UP',
  ICON_TIME = 'ICON_TIME',
  ICON_WARNING = 'ICON_WARNING',
}

export interface ReportWizardIconType {
  // Daha önceden clojure tarafında oluşturulmuş raporlarda (eski ismiyle widgets) bulunan fontawesome icon isimleriyle uyumlu çalışması için eklenen alan
  icon: IconDefinition;
  label?: string;
  value: ReportWizardIconShortCodes;
  valueCompatibility?: string;
}

export const ReportWizardIcons: ReportWizardIconType[] = [
  {
    valueCompatibility: 'fa-building-0',
    icon: faBuilding,
    value: ReportWizardIconShortCodes.ICON_BUILDING,
  },
  {
    valueCompatibility: 'shopping-cart',
    icon: faCartShopping,
    value: ReportWizardIconShortCodes.ICON_CART,
  },
  {
    valueCompatibility: 'fa-dashboard',
    icon: faChartNetwork,
    value: ReportWizardIconShortCodes.ICON_DASHBOARD,
  },
  {
    valueCompatibility: 'tag',
    icon: faTag,
    value: ReportWizardIconShortCodes.ICON_TAG,
  },
  {
    valueCompatibility: 'fa-car',
    icon: faCar,
    value: ReportWizardIconShortCodes.ICON_CAR,
  },
  {
    valueCompatibility: 'bed',
    icon: faBed,
    value: ReportWizardIconShortCodes.ICON_BED,
  },
  {
    valueCompatibility: 'fa-comment',
    icon: faComment,
    value: ReportWizardIconShortCodes.ICON_COMMENT,
  },
  {
    valueCompatibility: 'frown-o',
    icon: faFaceFrown,
    value: ReportWizardIconShortCodes.ICON_FACE_FROWN,
  },
  {
    valueCompatibility: 'fa-smile-o',
    icon: faFaceSmile,
    value: ReportWizardIconShortCodes.ICON_FACE_SMILE,
  },
  {
    valueCompatibility: 'meh-o',
    icon: faFaceMeh,
    value: ReportWizardIconShortCodes.ICON_FACE_MEH,
  },
  {
    valueCompatibility: 'fa-time-end',
    icon: faHourglassEnd,
    value: ReportWizardIconShortCodes.ICON_TIME,
  },
  {
    valueCompatibility: 'exclamation-triangle',
    icon: faTriangleExclamation,
    value: ReportWizardIconShortCodes.ICON_WARNING,
  },
  {
    valueCompatibility: 'fa-quote-left',
    icon: faQuoteLeft,
    value: ReportWizardIconShortCodes.ICON_QUOTE,
  },
  {
    valueCompatibility: 'thumbs-o-up',
    icon: faThumbsUp,
    value: ReportWizardIconShortCodes.ICON_THUMBS_UP,
  },
  {
    valueCompatibility: 'fa-thumbs-o-down',
    icon: faThumbsDown,
    value: ReportWizardIconShortCodes.ICON_THUMBS_DOWN,
  },
]
  .map((item) => ({ ...item, label: item.value.replace('ICON_', '') }))
  .sort((a, b) => a.value.localeCompare(b.value));

export function findReportWizardIcon(iconName: string): ReportWizardIconType {
  return ReportWizardIcons.find((item) => item.value === iconName || item.valueCompatibility === iconName);
}
