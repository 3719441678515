import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PasswordInput, useMantineTheme } from '@mantine/core';
import React from 'react';
import classes from './PasswordInput.module.scss';

const PasswordToggleIcon: React.FC<{
  reveal: boolean;
}> = ({ reveal }) => {
  const theme = useMantineTheme();
  return <FontAwesomeIcon color={theme.colors.gray[2]} icon={reveal ? faEyeSlash : faEye} />;
};

export const PasswordInputConfig = PasswordInput.extend({
  defaultProps: {
    visibilityToggleIcon: PasswordToggleIcon,
  },
  classNames: {
    innerInput: classes.innerInput,
  },
});
