import { Textarea } from '@mantine/core';
import classes from './Textarea.module.scss';

export const TextareaConfig = Textarea.extend({
  classNames: {
    input: classes.input,
  },
  defaultProps: {
    autosize: true,
    minRows: 2,
    maxRows: 4,
  },
});
