import { createTheme, em } from '@mantine/core';
import {
  AccordionConfig,
  ActionIconConfig,
  AvatarConfig,
  BadgeConfig,
  ButtonConfig,
  CheckboxConfig,
  ColorInputConfig,
  ColorSwatchConfig,
  DatePickerInputConfig,
  DateTimePickerConfig,
  DividerConfig,
  DrawerConfig,
  GroupConfig,
  InputConfig,
  InputWrapperConfig,
  MantineFontConfig,
  MantineRadiusConfig,
  MenuConfig,
  ModalConfig,
  ModalOverlayConfig,
  MonthPickerInputConfig,
  NotificationConfig,
  PISANO_COLORS,
  PasswordInputConfig,
  PopoverConfig,
  RadioConfig,
  ScrollAreaConfig,
  StackConfig,
  SwitchConfig,
  TableConfig,
  TabsConfig,
  TextConfig,
  TextareaConfig,
  TooltipConfig,
  YearPickerInputConfig,
} from './mantine';

export type MediaQuery = 'XXS' | 'XS' | 'SM' | 'MD' | 'LG' | 'XL' | 'XXL';

export const MEDIA_QUERIES: Record<MediaQuery, number> = {
  XXS: 320,
  XS: 576,
  SM: 992,
  MD: 1200,
  LG: 1366,
  XL: 1600,
  XXL: 1920,
};

export const PISANO_THEME = createTheme({
  colors: PISANO_COLORS,
  components: {
    Accordion: AccordionConfig,
    ActionIcon: ActionIconConfig,
    Avatar: AvatarConfig,
    Badge: BadgeConfig,
    Button: ButtonConfig,
    Checkbox: CheckboxConfig,
    ColorInput: ColorInputConfig,
    ColorSwatch: ColorSwatchConfig,
    DatePickerInput: DatePickerInputConfig,
    DateTimePicker: DateTimePickerConfig,
    Divider: DividerConfig,
    Drawer: DrawerConfig,
    Group: GroupConfig,
    Input: InputConfig,
    InputWrapper: InputWrapperConfig,
    Menu: MenuConfig,
    Modal: ModalConfig,
    ModalOverlay: ModalOverlayConfig,
    MonthPickerInput: MonthPickerInputConfig,
    Notification: NotificationConfig,
    PasswordInput: PasswordInputConfig,
    Popover: PopoverConfig,
    Radio: RadioConfig,
    ScrollArea: ScrollAreaConfig,
    Stack: StackConfig,
    Switch: SwitchConfig,
    Table: TableConfig,
    Tabs: TabsConfig,
    Text: TextConfig,
    Textarea: TextareaConfig,
    Tooltip: TooltipConfig,
    YearPickerInput: YearPickerInputConfig,
  },
  black: '#000000',
  white: '#ffffff',
  cursorType: 'pointer',
  lineHeights: {
    xs: '1.5',
    sm: '1.5',
    md: '1.5',
    lg: '1.5',
    xl: '1.5',
  },
  fontFamily: MantineFontConfig.fontFamily,
  fontSizes: MantineFontConfig.fontSizes,
  headings: MantineFontConfig.headings,
  primaryShade: {
    light: 5,
    dark: 8,
  },
  radius: MantineRadiusConfig,
  other: {
    fontFamilySecondary: MantineFontConfig.fontFamily,
  },
  breakpoints: Object.entries(MEDIA_QUERIES).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.toLowerCase()]: em(value),
    }),
    {},
  ),
});
