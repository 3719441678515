import { Table } from '@mantine/core';
import classes from './Table.module.scss';

export const TableConfig = Table.extend({
  classNames: {
    table: classes.table,
    tbody: classes.tbody,
    td: classes.td,
    th: classes.th,
    thead: classes.thead,
    tr: classes.tr,
  },
});
