import { Menu } from '@mantine/core';
import classes from './Menu.module.scss';

export const MenuConfig = Menu.extend({
  classNames: {
    dropdown: classes.dropdown,
    item: classes.item,
  },
  defaultProps: {
    withArrow: false,
    zIndex: 2,
  },
});
